import React, { ReactElement } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Layout } from '../components/common';
import ContentImageRow from '../components/common/ContentImageRow';
import ContentHero from '../components/common/contentSections/ContentHero';
import ImageContentRow from '../components/common/ImageContentRow';
import { ContentData } from '../utils/entities';
import { Image } from '../utils/image';

const howItWorksList: { title: string; desc: string }[] = [
    {
        title: `Register User / Company`,
        desc: `WorkForce / Employee Identity management - Useful Unified view to view all employees and assign them into different groups based on different departments and permission to different resources. `,
    },
    {
        title: `Get Users Onboard`,
        desc: `WorkForce / Employee Identity management - Useful Unified view to view all employees and assign them into different groups based on different departments and permission to different resources. `,
    },
    {
        title: `Subcribe `,
        desc: `WorkForce / Employee Identity management - Useful Unified view to view all employees and assign them into different groups based on different departments and permission to different resources. `,
    },
];

const teamMembersList: { title: string; desc: string; imageUrl: string }[] = [
    {
        title: `CEO : Mr Williams Charles`,
        desc: `Reduce the amount of forms to fill for endless applications and provide api
            interoperability of data between user and company requiring data.`,
        imageUrl: `http://via.placeholder.com/480x480`,
    },
    {
        title: `COO : Mrs Bola Adenuga`,
        desc: `Reduce the amount of forms to fill for endless applications and provide api
            interoperability of data between user and company requiring data.`,
        imageUrl: `http://via.placeholder.com/480x480`,
    },
    {
        title: `HR : Mr Remi Martins `,
        desc: `Reduce the amount of forms to fill for endless applications and provide api
            interoperability of data between user and company requiring data.`,
        imageUrl: `http://via.placeholder.com/480x480`,
    },
];

const AboutUsContentData: ContentData[] = [
    {
        id: 1,
        title: `Our Vision`,
        desc: `Our vision is to be the number 1 identity management cloud service in Africa, providing end-users with the means to do their job effectively while having the best user experience throughout their authentication and authorization lifecycle.`,
        imageUrl: `images/about-us-vision.svg`,
    },
    {
        id: 2,
        title: `Our Mission`,
        desc: `Our mission is to automate the interaction between individuals, organizations and identity domains as seamlessly as possible with high level of security assurance`,
        imageUrl: `images/about-us-mission.svg`,
    },
];

const AboutUs = (): ReactElement => (
    <Layout>
        <Container>
            <ContentHero
                heading="All You Need To Know About Us"
                desc="StackIAM is a cloud SASS independent Identity Provider, Validation and Management solution designed for customers, governments and businesses."
                imageUrl="/images/about-us-hero.svg"
            />
            <Row
                style={{
                    paddingLeft: `6rem`,
                    paddingRight: `6rem`,
                    paddingTop: `1rem`,
                    paddingBottom: `2rem`,
                }}
                noGutters
            >
                <Col
                    md="12"
                    lg="6"
                    style={{
                        textAlign: `center`,
                        // paddingTop: '3rem',
                        color: `#535b6c`,
                        // paddingBottom: '5rem',
                    }}
                >
                    <div
                        style={{
                            fontSize: `1.5rem`,
                            color: `#3c4043`,
                            fontWeight: `bold`,
                            paddingTop: `2rem`,
                            textAlign: `center`,
                        }}
                    >
                        Company Description
                    </div>
                    <p
                        style={{
                            padding: `1rem`,
                            color: `#535b6c`,
                            textAlign: `center`,
                        }}
                    >
                        Creates a potential marketplace for People where
                        business can directly meet users. It also provides an
                        interoperable access to user data across different
                        industries. Users will be able to use the Service as a
                        secure single-sign on provider and move their data
                        across different applications. Also provide a user
                        Identity Api allowing user data interoperable and
                        accessible across different platforms. EveryUser in
                        StackIAM has 1 identity on the platform.
                    </p>
                </Col>
                <Col
                    md="12"
                    lg="6"
                    style={{
                        textAlign: `center`,
                        // paddingTop: '3rem',
                        color: `#535b6c`,
                        // paddingBottom: '5rem',
                    }}
                >
                    <Image src="/images/about-us-company-desc@3x.png" />
                </Col>
            </Row>
            <Row noGutters>
                <Col
                    xs="12"
                    style={{
                        textAlign: `center`,
                        paddingTop: `3rem`,
                        color: `#2e598f`,
                        paddingBottom: `3rem`,
                    }}
                >
                    <div
                        style={{
                            fontSize: `2rem`,
                            paddingBottom: `1rem`,
                        }}
                    >
                        How It Works
                    </div>
                </Col>
            </Row>
            <Row noGutters>
                {howItWorksList.map(({ title, desc }) => (
                    <Col
                        key={title}
                        md="12"
                        lg="4"
                        style={{ textAlign: `center` }}
                    >
                        <div
                            style={{
                                fontSize: `1.2rem`,
                                fontWeight: `bold`,
                                paddingTop: `1rem`,
                            }}
                        >
                            {title}
                        </div>
                        <p
                            style={{
                                padding: `1rem`,
                                paddingLeft: `1rem`,
                                paddingRight: `1rem`,
                                color: `#535b6c`,
                            }}
                        >
                            {desc}
                        </p>
                    </Col>
                ))}
            </Row>
            {AboutUsContentData.map((item) => {
                if (item.id % 2 === 0) {
                    return <ContentImageRow key={item.id} {...item} />;
                } else {
                    return <ImageContentRow key={item.id} {...item} />;
                }
            })}
            <Row noGutters>
                <Col
                    xs="12"
                    style={{
                        textAlign: `center`,
                        paddingTop: `3rem`,
                        color: `#2e598f`,
                        paddingBottom: `3rem`,
                    }}
                >
                    <div
                        style={{
                            fontSize: `2rem`,
                            paddingBottom: `1rem`,
                        }}
                    >
                        Our Team Members
                    </div>
                </Col>
            </Row>
            <Row style={{ marginBottom: `5rem` }} noGutters>
                {teamMembersList.map(({ title, desc, imageUrl }) => (
                    <Col
                        key={title}
                        md="12"
                        lg="4"
                        style={{ textAlign: `center` }}
                    >
                        <Image
                            style={{
                                height: `16rem`,
                                width: `16rem`,
                                borderRadius: `1rem`,
                            }}
                            src={imageUrl}
                        />
                        <div
                            style={{
                                fontSize: `1.2rem`,
                                fontWeight: `bold`,
                                paddingTop: `1rem`,
                            }}
                        >
                            {title}
                        </div>
                        <p
                            style={{
                                padding: `1rem`,
                                paddingLeft: `1rem`,
                                paddingRight: `1rem`,
                                color: `#535b6c`,
                            }}
                        >
                            {desc}
                        </p>
                    </Col>
                ))}
            </Row>
        </Container>
    </Layout>
);

export default AboutUs;
